/* Facturas.css */

/* Estilos para la tabla y el encabezado fijo */
.table-responsive {
  position: relative;
  height: 400px; /* Ajusta la altura según tus necesidades */
  overflow: auto;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10; /* Ajusta el z-index para que esté debajo del datepicker */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

/* Asegúrate de que el datepicker tenga un z-index mayor */
.react-datepicker-popper {
  z-index: 9999;
}
/* Estilos personalizados para react-select */
.select2-selection__control {
  z-index: 1000; /* Ajusta el z-index para que esté por encima de otros elementos */
}

.select2-selection__menu {
  z-index: 1050; /* Asegúrate de que el menú desplegable tenga un z-index mayor */
}